<template>
  <div class="app-aside">
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      background-color="#f8f9fb"
      text-color="#303133"
      active-text-color="#409EFF"
      unique-opened
      router
    >
      <el-menu-item index="/" style="padding-left: 25px">
        <template slot="title">
          <i class="fa fa-newspaper-o"></i>
          <span style="padding-left: 15px">首页</span>
        </template>
      </el-menu-item>
      <template v-for="(item, i) in menuList">
        <el-submenu v-if="null!=item.subMenuList&&item.subMenuList.length>0" :key="i" :index="`/`+`${i}`">
          <template slot="title" >
            <i v-if="item.icon" :class="[item.icon]"></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item  v-for="(sub, j) in item && item.subMenuList" :key="j" :index="`/`+sub.href">
            <i v-if="sub.icon" :class="[sub.icon]"></i>
            <span>{{ sub.name }}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-else :key="i" :index="`/`+item.href" style="padding-left: 25px">
          <i :class="[item.icon]" ></i>
          <span slot="title" style="padding-left: 15px">{{ item.name }}</span>
        </el-menu-item>

      </template>
<!--      <el-menu-item index="/">-->
<!--        <template slot="title" style="padding-left: 40px" >-->
<!--          <i class="fa fa-slack"></i>-->
<!--          <span>&thinsp;&thinsp;&thinsp;</span>-->
<!--          <span>首页</span>-->
<!--        </template>-->
<!--      </el-menu-item>-->
<!--      <component class="menu-item" v-for="menu in menuList" :key="menu.id" :index="'/'+menu.href"-->
<!--                 :is="(menu.subMenuList&&menu.subMenuList.length>0)?'el-submenu':'el-menu-item'">-->
<!--        <template slot="title" style="padding-left: 40px">-->
<!--          <i :class="[menu.icon]"></i>-->
<!--          <span v-if="!menu.subMenuList||menu.subMenuList.length<=0">&thinsp;&thinsp;&thinsp;</span>-->
<!--          <span>{{ menu.name }}</span>-->
<!--        </template>-->
<!--        <template v-if="menu.subMenuList && menu.subMenuList.length>0">-->
<!--          <el-menu-item class="menu-item" v-for="v in menu.subMenuList" :key="v.id" :index="'/'+v.href">-->
<!--            <i :class="[v.icon]"></i>-->
<!--            <span slot="title">{{ v.name }}</span>-->
<!--          </el-menu-item>-->
<!--        </template>-->
<!--      </component>-->

    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'AppAside',
  data() {
    return {
      isCollapse: true
    };
  },
  computed: {
    menuList: function () {
      return this.$store.state.menuList.filter(function (menu) {
        return 1 === menu.shown
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//侧边栏设置高度
.app-aside {
  height: 100%;
  .el-menu {
    height: 100%;
    border-right: 0 none;
  }
}

</style>
