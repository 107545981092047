<template>
  <div class="app-header">
    <!--    左侧面包屑导航-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
<!--      <el-breadcrumb-item >首页</el-breadcrumb-item>-->
      <el-breadcrumb-item>{{ this.$store.state.routeCnName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    右侧用户信息展示-->
    <div class="header-right">
      <span class="userName">
        用户：{{ userInfo.userName }}
      </span>
      <el-dropdown>
      <span class="el-dropdown-link">
        <!--        用户头像使用 avatar组件-->
        <el-avatar
          :size="30"
          :src="userInfo.portrait || '/favicon.ico'">
        </el-avatar>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><el-button type="text" size="mini" @click="goFullScreen" >全屏</el-button></el-dropdown-item>
          <el-dropdown-item>{{ userInfo.userName }}</el-dropdown-item>
          <!--这里@click.native是告知当前事件是原生事件，它会给当前事件的组件el-dropdown-item的根元素赋点击事件-->
          <el-dropdown-item
            divided
            @click.native="handleLogout"
          >退出
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/services/user'
import {redirectLogin} from '@/utils/someCommen'

export default {
  name: 'AppHeader',
  data () {
    return {
      userInfo: {}
    }
  },
  created () {
    // 加载用户信息
    this.loadUserInfo()
  },
  methods: {
    goFullScreen() {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },
    async loadUserInfo () {
      const { data } = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },
    handleLogout () {
      this.$confirm('确认要退出吗?', '退出提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        // 1.清除 store 和 本地存储中的用户信息
        this.$store.commit('setUser',null)
        // 2.跳转登录页
        // this.$router.push({
        //   name:'login',
        //   query: {
        //     //将本次路由的 fullPath 传递给 login页面
        //     redirect: this.$route.fullPath
        //   }
        // })
        redirectLogin()
        this.$message.success('退出成功')
      }).catch(() => {
        this.$message.info('已取消退出')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-right {
    display: flex;
    align-items: center;

    .el-dropdown-link {
      margin-left: 20px;
    }
  }

}
</style>
