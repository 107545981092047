<template>
  <el-container>
    <el-aside width="200px">
      <!--      侧边栏组件-->
      <app-aside></app-aside>
    </el-aside>
    <el-container>
      <el-header>
        <app-header></app-header>

      </el-header>
      <tags-view />
      <el-main>
        <!--        设置子路由出口-->
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive">
            <!-- 这里是会被缓存的视图组件，比如 Home！ -->
          </router-view>
        </keep-alive>

        <router-view v-if="!$route.meta.keepAlive">
          <!-- 这里是不会被缓存的视图组件，比如 Profile！ -->
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
//引入侧边栏组件
import AppAside from './components/AppAside'
//引入头部组件
import AppHeader from './components/AppHeader'

export default {
  name: 'LayoutIndex',
  components: { AppHeader, AppAside },
  comments: {
    AppAside,
    AppHeader
  }
}
</script>

<style lang="scss" scoped>
  .el-container {
    height: 100vh;
  }
  .el-aside {
    background-color: #f8f9fb;
  }

  .el-header {
    background-color: #f8f9fb;
  }


</style>
